import { Component, ChangeDetectorRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { content } from '../../../content/content';

import { GraphQLService } from '../../services/graphql-service/graphql.service';

import { ConfirmService } from '../../services/confirm/confirm.service';
import { ModalDialogComponent } from '@vg-constellation/angular-13/modal-dialog';

import {
  confirmationType,
  confirmationTypeResponse,
  confirmObject, confirmRes,
  selectedValuesObject,
} from '../../models/confirms.model';
import { LoggerService } from '../../services/logger/logger.service';
import { CaasService } from '../../services/caas/caas.service';
import { ConfirmsContent, dropdownObject } from '../../models/caas.model';
import PKG from '../../../../package.json';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import { cloneDeep } from '@apollo/client/utilities';
import { DropdownMenuData } from '@vg-constellation/angular-13/dropdown';

const SCROLLVALUE = 100
const ALL_CONFIRMAION_TYPE = 'All confirmation type'
const ALL_MONTHS = 'All months';
const GRADIENT_LEFT = 'gradient-left';
const GRADIENT_RIGHT = 'gradient-right';

@Component({
  selector: 'app-confirm-page',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {

  content = content;
  errorBannerConfig = {
    size: 'medium',
    heading: content.contentData.api_error_heading,
    body: content.contentData.api_error_body,
    closable: false,
    collapsible: false,
    type: 'error',
  };
  shouldShowErrorBanner = false;
  shouldShowErrorBannerForNoData = false;
  isLoaderIndicator = false;
  contentBody: string;
  tetriaryNavigationOptions: Array<dropdownObject>;

  years: string[] = [new Date().getFullYear().toString()];
  months: string[] = [];
  confirmationType: string[] = [ALL_CONFIRMAION_TYPE];
  confirmList: confirmObject[] = [];
  standardConfirmList: confirmObject[] = [];
  filteredMasterConfirmsList: confirmObject[] = [];
  showMoreIndex;
  counter = 0;
  filterOn = false;
  defaultSize = 20;

  selectedValues: selectedValuesObject = {
    year: new Date().getFullYear().toString(),
  };
  confirmationTypesList: confirmationType[];
  selectedMonth: string;
  selectedConfirmationType: string;
  errorStateYear = false;
  errorTextYear = '';
  confirmsFetchNoError = true;
  confirmsFetchErrorText = '';
  shouldShowErrorBannerForApiFailure = false;
  apiFailureErrorBody = '';
  modelheading = 'All accounts';
  tableheading: string = content.confirmationsContent.tableSection.confirmationTypeColumnLabel;
  showConfirmType = false;
  showdescription = false;
  showpdf = false;
  errorPdfDownload = 'The information is currently unavailable';
  confirmListAllData: confirmObject[] = [];
  confirmsContent: ConfirmsContent;
  viewDropdownContent: any = [];
  dropdownMenuOptions: DropdownMenuData;

  downloadLinkLabel = environment.downloadAccountLink.label
  downloadLinkUrl = environment.downloadAccountLink.url

  reset = 'Reset';
  month = '';
  confirmType = '';
  showOptOutButton = false;
  @ViewChild(ModalDialogComponent) modal: ModalDialogComponent;
  @ViewChild('errorModal') errorModal: ModalDialogComponent;

  @ViewChild('contentElement') elRef: ElementRef;
  hideShowMore = true;
  hideReset = true;
  dateSortingState = 'descending';
  descriptionSortingState = 'none';
  confirmTypeSortingState = 'none';

  constructor(
    private readonly confirmService: ConfirmService,
    private readonly graphQLService: GraphQLService,
    private readonly loggerService: LoggerService,
    private readonly caasService: CaasService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getCaasData();
    this.retrieveData();
    this.dropdownMenuOptions = { groups: [{ items: this.viewDropdownContent }] };
  }

  async retrieveData() {
    try {
      for (let i = 1; i < environment.No_OF_YEARS; i++) {
        this.years.push((new Date().getFullYear() - i).toString());
      }
      this.selectedValues.year = this.years[0];
      const confirmationTypesArray = await this.getConfirmTypes();
      this.confirmationTypesList = confirmationTypesArray.types;
      await this.getMonthAndConfirms();
    } catch (err) {
      console.log('inside catch block');
      this.isLoaderIndicator = false;
      this.shouldShowErrorBannerForApiFailure = true;
      this.apiFailureErrorBody = 'Unable to fetch data. Try again later.';
    }

  }

  // eslint-disable-next-line max-statements
  async getMonthAndConfirms() {
    try {
      this.isLoaderIndicator = true;
      this.hideReset = true;
      this.filterOn = false;
      this.showMoreIndex = 0;
      this.months = [ALL_MONTHS];
      this.confirmationType = [ALL_CONFIRMAION_TYPE];
      this.selectedMonth = ALL_MONTHS;
      this.month = ALL_MONTHS;
      this.confirmType = ALL_CONFIRMAION_TYPE;
      this.selectedConfirmationType = ALL_CONFIRMAION_TYPE;
      const confirms = await this.getConfirm(this.selectedValues);
      this.confirmList = confirms.confirmations;
      this.confirmList.forEach(confirmsObj => {
        const tempObj = this.confirmationTypesList.find(conf => conf.confirmationTypeCode === confirmsObj.confirmationTypeCode)
        confirmsObj.confirmationEvent = tempObj?.description
      });
      if (this.confirmList.length === 0) {
        this.shouldShowErrorBannerForNoData = true;
        this.hideShowMore = true;
      } else {
        this.shouldShowErrorBannerForNoData = false;
      }
      this.confirmList.forEach((cnfrmObj) => {
        cnfrmObj.confirmationDescription = cnfrmObj.confirmationDescription === null ? '' : cnfrmObj.confirmationDescription
        cnfrmObj.endDate = moment(cnfrmObj.endDate).format('MM/DD/YYYY')
        const check = moment(cnfrmObj.endDate, 'MM/DD/YYYY');
        const currentMonth = check.format('MMMM');
        cnfrmObj.month = currentMonth;
        this.months.push(currentMonth);
        this.confirmationType.push(cnfrmObj.confirmationEvent || '');
      });
      this.months = [...new Set(this.months)];
      this.confirmationType = [...new Set(this.confirmationType)];
      this.months.sort(function (a, b) {
        return environment.ALL_MONTHS.indexOf(a.toUpperCase()) - environment.ALL_MONTHS.indexOf(b.toUpperCase());
      });

      // sort by date
      this.confirmList.sort((a, b) =>
        moment(a.endDate) > moment(b.endDate) ? -1 : moment(a.endDate) < moment(b.endDate) ? 1 : 0
      );
      this.dateSortingState = 'descending';
      this.confirmTypeSortingState = 'none';
      this.descriptionSortingState = 'none';

      // sort by type on date match
      this.confirmList.sort((a, b) =>
        moment(a.endDate) === moment(b.endDate) ? ((b.confirmationEvent && a.confirmationEvent) ? b.confirmationEvent.localeCompare(a.confirmationEvent) : 0) : 0
      );

      // sort by description where dates and type match
      this.confirmList.sort((a, b) =>
        (a.endDate === b.endDate && a.confirmationEvent === b.confirmationEvent) ? a.confirmationDescription.localeCompare(b.confirmationDescription) : 0
      );
      this.standardConfirmList = this.confirmList;
      this.showMoreConfirms(null);
      this.isLoaderIndicator = false;
    } catch (err: any) {
      this.shouldShowErrorBannerForNoData = true;
      this.hideShowMore = true;
      this.confirmList = [];
      this.standardConfirmList = [];
      this.isLoaderIndicator = false;
      this.loggerService.error({
        message: err,
        app: PKG.deployment.appPrefix || 'RQF',
      });
      this.confirmsFetchNoError = false;
      this.confirmsFetchErrorText = `${err.status} ${err.statusText}`;
    }
  }

  resetFilters() {
    this.month = this.selectedMonth;
    this.confirmType = this.selectedConfirmationType;
    this.hideReset = true;
  }


  showMoreConfirms(event) {
    this.showMoreIndex++;
    const totalRecordsToShow = this.showMoreIndex * this.defaultSize;
    const masterList = this.filterOn ? this.filteredMasterConfirmsList : this.confirmList;
    if (totalRecordsToShow < masterList.length) {
      this.standardConfirmList = masterList.slice(0, totalRecordsToShow);
      this.hideShowMore = false;
    } else {
      this.standardConfirmList = masterList;
      this.hideShowMore = true;
    }
    setTimeout(() => {
      this.checkLeftArrowActiveActions('SHOW_MORE');
    }, 300);
    if (event) {
      this.counter++;
      this.changeDetector.detectChanges();
      document.getElementById(`download-icon-${this.counter * this.defaultSize}`)?.focus();
    }
  }
  getCaasData() {
    const response = this.caasService.getCaasData();
    if (response) {
      this.confirmsContent = response.Data.confirms.content;
      this.contentBody = this.confirmsContent.apiErrorBody
    }
    this.tetriaryNavigationOptions = Object.keys(this.confirmsContent.tabOptions).sort().map(key =>
    ({
      'optionLabel': this.confirmsContent.tabOptions[key].optionLabel,
      'optionUrl': this.confirmsContent.tabOptions[key].optionUrl
    })
    );

    this.viewDropdownContent = Object.keys(this.confirmsContent.dropdownOptions).sort().map(key =>
    ({
      'labelText': this.confirmsContent.dropdownOptions[key].optionLabel,
      'id': this.confirmsContent.dropdownOptions[key].optionUrl ? this.confirmsContent.dropdownOptions[key].optionUrl : '0'
    })
    );
  }

  /*eslint complexity: ["error", 10]*/
  /*eslint-env es6*/

  async getSelectedValue(e, type: string) {
    try {
      if (type === 'YEAR') {
        this.selectedValues.year = e.target.options[e.target.options.selectedIndex].text;
        this.checkLeftArrowActiveActions('YEAR_CHANGE');
        await this.getMonthAndConfirms();
      } else {
        if (this.month === this.selectedMonth && this.confirmType === this.selectedConfirmationType) {
          this.hideReset = true;
        } else {
          this.hideReset = false;
        }
      }
    } catch (err: any) {
      this.isLoaderIndicator = false;
      this.confirmsFetchNoError = false;
      this.confirmsFetchErrorText = `${err.status} ${err.statusText}`;
    }
  }
  trackByFn(index) {
    return index;
  }

  sortColumn(column) {
    const masterList = this.filterOn ? this.filteredMasterConfirmsList : this.confirmList;
    const totalRecordsToShow = this.showMoreIndex * this.defaultSize;
    if (column === 'date') {
      this.dateSort(masterList);
    } else if (column === 'confirmationDescription') {
      this.descriptionSort(masterList, column);
    } else {
      this.confirmationTypeSort(masterList, column);
    }
    this.standardConfirmList = masterList.slice(0, totalRecordsToShow);
  }

  private confirmationTypeSort(masterList: confirmObject[], column: string) {
    if (this.confirmTypeSortingState === 'ascending') {
      this.confirmTypeSortingState = 'descending';
      this.dateSortingState = 'none';
      this.descriptionSortingState = 'none';
      masterList.sort((a, b) => b[column].localeCompare(a[column])
      );
      this.standardConfirmList = [...masterList];
    } else {
      this.confirmTypeSortingState = 'ascending';
      this.dateSortingState = 'none';
      this.descriptionSortingState = 'none';
      masterList.sort((a, b) => a[column].localeCompare(b[column])
      );
      this.standardConfirmList = [...masterList];
    }
    setTimeout(() => {
      this.checkLeftArrowActiveActions('SORT');
    }, 300);
  }

  private descriptionSort(masterList: confirmObject[], column: string) {
    if (this.descriptionSortingState === 'ascending') {
      this.descriptionSortingState = 'descending';
      this.dateSortingState = 'none';
      this.confirmTypeSortingState = 'none';
      masterList.sort((a, b) => b[column].localeCompare(a[column])
      );
      this.standardConfirmList = [...masterList];
    } else {
      this.descriptionSortingState = 'ascending';
      this.dateSortingState = 'none';
      this.confirmTypeSortingState = 'none';
      masterList.sort((a, b) => a[column].localeCompare(b[column])
      );
      this.standardConfirmList = [...masterList];
    }
    setTimeout(() => {
      this.checkLeftArrowActiveActions('SORT');
    }, 300);
  }

  private dateSort(masterList: confirmObject[]) {
    if (this.dateSortingState === 'descending') {
      this.dateSortingState = 'ascending';
      this.descriptionSortingState = 'none';
      this.confirmTypeSortingState = 'none';
      masterList.sort((a, b) => moment(a.endDate) < moment(b.endDate) ? -1 : moment(a.endDate) > moment(b.endDate) ? 1 : 0
      );
      this.standardConfirmList = [...masterList];
    } else {
      this.dateSortingState = 'descending';
      this.descriptionSortingState = 'none';
      this.confirmTypeSortingState = 'none';
      masterList.sort((a, b) => moment(a.endDate) > moment(b.endDate) ? -1 : moment(a.endDate) < moment(b.endDate) ? 1 : 0
      );
      this.standardConfirmList = [...masterList];
    }
  }

  // eslint-disable-next-line max-statements
  async searchConfirms() {
    try {
      this.hideReset = true;
      this.selectedMonth = this.month;
      this.selectedConfirmationType = this.confirmType;
      this.shouldShowErrorBannerForNoData = false;
      this.counter = 0;
      this.confirmsFetchNoError = true;
      if (this.selectedMonth !== ALL_MONTHS) {
        this.showMoreIndex = 0;
        this.filterOn = true;
        this.standardConfirmList = this.confirmList.filter(
          (confirmObj) => confirmObj.month === this.selectedMonth,
        );
        this.filteredMasterConfirmsList = cloneDeep(this.standardConfirmList)
      } else {
        this.standardConfirmList = this.confirmList;
      }

      if (this.selectedConfirmationType !== ALL_CONFIRMAION_TYPE) {
        this.showMoreIndex = 0;
        this.filterOn = true;
        this.standardConfirmList = this.standardConfirmList.filter(
          (confirmObj) => confirmObj.confirmationEvent === this.selectedConfirmationType,
        );
        this.filteredMasterConfirmsList = cloneDeep(this.standardConfirmList)
        if (this.standardConfirmList.length === 0) {
          this.shouldShowErrorBannerForNoData = true;
          this.hideShowMore = true;
        }
      }
      if (this.selectedConfirmationType === ALL_CONFIRMAION_TYPE && this.selectedMonth === ALL_MONTHS) {
        this.filterOn = false;
        this.filteredMasterConfirmsList = [];
        this.showMoreIndex = this.showMoreIndex - 1;
        if (this.confirmList.length === 0) {
          this.shouldShowErrorBannerForNoData = true;
          this.hideShowMore = true;
        }
      }
      this.showMoreConfirms(null);
    } catch (err: any) {
      this.confirmsFetchNoError = false;
      this.confirmsFetchErrorText = `${err.status} ${err.statusText}`;
    }
  }

  private getConfirm(searchData: selectedValuesObject): Promise<confirmRes> {
    return this.confirmService.getConfirms(searchData).toPromise();
  }
  private getConfirmTypes(): Promise<confirmationTypeResponse> {
    return this.confirmService.getConfirmTypes().toPromise();
  }
  downloadpdf(pdfURL: string, event) {
    this.isLoaderIndicator = true;
    this.confirmService.downloadPdf(pdfURL).subscribe(
      (data: Blob) => {
        this.isLoaderIndicator = false;
        this.downloadFile(data, 'confirmation.pdf');
      },
      (error) => {
        this.errorModal.openModalDialog(event)
        this.isLoaderIndicator = false;
        console.error(error);
      },
    );
  }

  downloadFile(response, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([response], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }
  viewRedirect(event) {
    if (event.activeItems[0] !== '0') {
      window.open(event.activeItems[0], "_self");
    }
  }
  keyHandler(event, link) {
    event.stopPropagation();
    this.downloadpdf(link, event);
  }
  closeErrorModal() {
    this.errorModal.closeDialogModal();
  }
  toggleAccountdownload(value: string) {
    this.tableheading = value;
    if (value === content.confirmationsContent.tableSection.viewDownloadColumnLabel) {
      this.showConfirmType = false;
      this.showdescription = false;
      this.showpdf = true;
    } else if (value === content.confirmationsContent.tableSection.descriptionColumnLabel) {
      this.showConfirmType = false;
      this.showdescription = true;
      this.showpdf = false
    } else if (value === content.confirmationsContent.tableSection.confirmationTypeColumnLabel) {
      this.showConfirmType = true;
      this.showdescription = false;
      this.showpdf = false
    }
    this.modal.closeDialogModal();
  }

  addRightGradient(element: HTMLElement) {
    const thElements = element.querySelectorAll('th.sticky-right');
    thElements.forEach((item) => item.classList.add(GRADIENT_RIGHT));

    const tdElements = element.querySelectorAll('td.sticky-right');
    tdElements.forEach((item) => item.classList.add(GRADIENT_RIGHT));
  }

  addRightScrollButton(element: HTMLElement) {
    const buttonView = element.querySelector('[data-id="scroll_right"]') as HTMLElement;
    buttonView.hidden = false;
  }

  removeRightGradient(element: HTMLElement) {
    const thElements = element.querySelectorAll('th.sticky-right');
    thElements.forEach((item) => item.classList.remove(GRADIENT_RIGHT));

    const tdElements = element.querySelectorAll('td.sticky-right');
    tdElements.forEach((item) => item.classList.remove(GRADIENT_RIGHT));
  }

  removeRightScrollButton(element: HTMLElement) {
    const buttonView = element.querySelector('[data-id="scroll_right"]') as HTMLElement;
    buttonView.hidden = true;
  }

  addLeftGradient(element: HTMLElement) {
    const thElements = element.querySelectorAll('th.sticky-left');
    thElements.forEach((item) => item.classList.add(GRADIENT_LEFT));

    const tdElements = element.querySelectorAll('td.sticky-left');
    tdElements.forEach((item) => item.classList.add(GRADIENT_LEFT));
  }

  addLeftScrollButton(element: HTMLElement) {
    const buttonView = element.querySelector('[data-id="scroll_left"]') as HTMLElement;
    buttonView.hidden = false;
  }

  removeLeftGradient(element: HTMLElement) {
    const thElements = element.querySelectorAll('th.sticky-left');
    thElements.forEach((item) => item.classList.remove(GRADIENT_LEFT));

    const tdElements = element.querySelectorAll('td.sticky-left');
    tdElements.forEach((item) => item.classList.remove(GRADIENT_LEFT));
  }

  removeLeftScrollButton(element: HTMLElement) {
    const buttonView = element.querySelector('[data-id="scroll_left"]') as HTMLElement;
    buttonView.hidden = true;
  }


  scrollLeft(): void {
    this.elRef.nativeElement.scrollLeft -= SCROLLVALUE;
  }

  scrollRight(): void {
    this.elRef.nativeElement.scrollLeft += SCROLLVALUE;
  }

  onScroll(): void {

    const tableView = this.elRef.nativeElement;
    if (tableView) {
      // table is scrolled all the way to the left or right
      // determine if left/right scroll button/gradients need to be removed
      if (tableView.scrollLeft + tableView.offsetWidth >= tableView.scrollWidth) {
        // scroll is all the way to the right
        this.removeRightScrollButton(tableView);
        this.removeRightGradient(tableView);
        this.addLeftScrollButton(tableView);
        this.addLeftGradient(tableView);
      } else if (tableView.scrollLeft === 0) {
        // scroll is all the way to the left
        this.removeLeftScrollButton(tableView);
        this.removeLeftGradient(tableView);
        this.addRightScrollButton(tableView);
        this.addRightGradient(tableView);
      } else {
        // scroll is in the middle
        this.addLeftScrollButton(tableView);
        this.addLeftGradient(tableView);
        this.addRightScrollButton(tableView);
        this.addRightGradient(tableView);
      }
    }
  }

  keyHandlerShowMore(event) {
    event.stopPropagation();
    this.showMoreConfirms(event)
  }


  checkLeftArrowActiveActions(action?: string) {
    const el = this.elRef.nativeElement.querySelector('[data-id="scroll_left"]') as HTMLElement;
    if (el && !el.hidden) {
      switch (action) {
        case 'YEAR_CHANGE': {
          this.scrollLeft();
          break;
        }
        case 'SHOW_MORE': {
          this.addLeftGradient(this.elRef.nativeElement);
          break;
        }
        case 'SORT': {
          this.addLeftGradient(this.elRef.nativeElement);
        }
      }
    }
  }
}
