import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ConfirmsObject } from '../../models/caas.model';
import { content } from '../../../content/content';

@Injectable({
  providedIn: 'root'
})
export class CaasService {
  private caasData: ConfirmsObject;
  constructor( private readonly http: HttpClient) {}

  getContent() {
    return this.http.get<ConfirmsObject>(`${environment.CONFIRMATIONS.BASE_URL}${environment.CONFIRMATIONS.CAAS_URL}`).pipe().toPromise().then((response) => {
      this.caasData = response;
    }).catch((err) => {
      console.log('Caas', err)
      this.caasData = content.caasContent;
    });
  }
  
  getCaasData(){
    return this.caasData;
  }
}
