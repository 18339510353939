import { RuntimeEnvironments, MSAEnvironments } from './runtime-environments';

export const environment = {
  production: true,
  performance: false,
  internal: false,
  deployment: 'production',
  site: 'retailExternal',
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  No_OF_YEARS: 6,
  ALL_MONTHS: ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"],
  GLOBAL_HEADER_STYLES: 'https://ccc.vgcontent.info/vg-vgn/latest/css/vgn.min.css?appPrefix=RQF',
  GLOBAL_HEADER_JS: 'https://ccc.vgcontent.info/vg-vgn/latest/js/vgn.min.js?appPrefix=RQF',
  GLOBAL_HEADER_TAXONOMY: 'https://rgh.ecs.retp.c1.vanguard.com/taxonomy',

  LOGON_URL: 'https://logon.vanguard.com/logon?TARGET_OVERRIDE=https://confirmations.web.vanguard.com',
  MEDALLIA_LIB:
    'https://mdl-medallia-digital.vanguard.com/pkg/1.0.0/web/external_libs/wdcusprem/2940/onsite/medallia-digital-embed.js',

  // CONSUMER_API: 'https://api.ecs.us-east-1.rett.c1.vanguard.com/xs1-secure-site-consumer-api/',
  GRAPHQL_URI: 'https://apps.ecs.retp.c1.vanguard.com/xs1-secure-site-consumer-api/graphql',
  LEGACY_URL:'https://personal.vanguard.com/us/XHTML/Confirms',
  GATEKEEPER_FEATURE_ID: 'ConfirmsRedirectCheck',

  // TEALEAF_LIB: 'https://api.ecs.us-east-1.rete.c1.vanguard.com/lah-statements-consumer/tealeaf',

  FLOODLIGHT_RTE: RuntimeEnvironments.PROD,
  MSA_ENVIRONMENT: MSAEnvironments.PRODUCTION,
  PERSONAL_DOMAIN: 'https://personal.vanguard.com',
  ADOBE_TARGET_ANTIFLICKER_JS:
    'https://corp.at2.assets.vgdynamic.info/files/js/core/antiflicker.min.js',
  C3_SERVERLESS_BUNDLE_JS:
    "https://mc3-static-assets.web.vanguard.com/vg-ccc-serverless/latest/vg-ccc-serverless.js",

  CONFIRMATIONS: {
    BASE_URL: 'https://apps.ecs.retp.c1.vanguard.com/xki-confirms-consumer',
    CONSUMER_ENDPOINT: '/confirmations/consumer',
    CLIENT_LOG: '/client-log',
    GET_YEAR: '/statements/getYears',
    GET_MONTH: '/statements/getMonths',
    STATEMENT_LIST: '/statements/getStatements',
    CAAS_URL: '/confirmations/caas',
    TEALEAF_LIB: '/tealeaf',
    GATEKEEPER_URL: '/gatekeeper',
    PDF_URL: '/confirmations/pdf',
    CONFIRMATION_TYPES: '/confirmations/confirmation-types',
    INTERNAL_URL: 'https://confirmations-i.web.vanguard.com?secureMessage=true'
  },
  downloadAccountLink: {
    label: 'Download center',
    url: 'https://personal1.vanguard.com/ofu-open-fin-exchange-webapp/ofx-welcome',
  },
};
