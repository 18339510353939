import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { confirmationTypeResponse, confirmRes } from 'src/app/models/confirms.model';
import { environment } from '../../../environments/environment';

const httpOptions = {
  withCredentials: true,
};

@Injectable({
  providedIn: 'root'
})


export class ConfirmService {
  constructor(
    private readonly http: HttpClient
  ) {
  }

  getConfirms(data): Observable<confirmRes> {
    const endpoint = `${environment.CONFIRMATIONS.BASE_URL}${environment.CONFIRMATIONS.CONSUMER_ENDPOINT}`;
    httpOptions['params'] = data;
    httpOptions['headers'] = {
      'urlFlag': 'getStatements'
    };
    return this.http.get<confirmRes>(endpoint, httpOptions);
  }
  getConfirmTypes(): Observable<confirmationTypeResponse> {
    const endpoint = `${environment.CONFIRMATIONS.BASE_URL}${environment.CONFIRMATIONS.CONFIRMATION_TYPES}`;
    httpOptions['params'] = null;
    httpOptions['headers'] = {
      'urlFlag': 'getConfirmTypes'
    };    
    return this.http.get<confirmationTypeResponse>(endpoint, httpOptions);
  }
  downloadPdf(confirmationId: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('confirmationId', confirmationId);
    headers = headers.set('urlFlag', 'getPdf');
    const endpoint = `${environment.CONFIRMATIONS.BASE_URL}${environment.CONFIRMATIONS.PDF_URL}`;
    return this.http.get(endpoint, { headers, responseType: 'blob' });
  }
}
