<title class='greeting_info_a11y_page_header' tabindex='-1'>
  {{ confirmsContent.browserTitle }}
</title>
<ss-lib-page-layout>
  <div inner>
    <div class='target-offer'></div>
    <div class='row'>
      <div class='col'>
        <secondary-nav-widget active-item="ActivityTab" ></secondary-nav-widget>
        <div class="tertiary-nav" data-testid="tertiary-nav">
          <div class="tertiary-nav__customize_account">
            <a c11n-link [attr.href]="downloadLinkUrl" data-testid="download-center-link" variant="secondary-independent"
              fontWeight="normal" [attr.aria-label]="'Download center account information'">
              <span class="c11n-link__text">{{ downloadLinkLabel }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <app-tetriary-navigation [options]='tetriaryNavigationOptions' activeLink='CONFIRMATIONS'>
        </app-tetriary-navigation>
      </div>
    </div>
    <div class='row'>
      <div class='col'>
        <c11n-banner *ngIf='shouldShowErrorBanner' [size]='errorBannerConfig.size' [heading]='errorBannerConfig.heading'
          [closable]='errorBannerConfig.closable' [collapsible]='errorBannerConfig.collapsible'
          [type]='errorBannerConfig.type'>
          <div class='c11n-text-md--crop'>{{errorBannerConfig.body}}</div>
        </c11n-banner>
      </div>
    </div>

    <div class='main-div'>
      <div class='line-copy'></div>
      <div class='container-fluid p-0'>
        <div class='header-div mt-3'>
          <div class='row'>
            <div class='col-12 col-md-7 col-lg-8 c11n-grid-story__cell'>
              <h1 class='sub-heading'>{{ confirmsContent.heading }}</h1>
            </div>
            <div class='col-12 col-md-5 col-lg-4 c11n-grid-story__cell view-select-wrapper mt-3 mt-md-0'>

              <div class='col-4 float-left-class mt-4 view-span-wrapper'>
                <span class='view-select-label me-3'>View:</span>
              </div>

              <div class='col-8 float-right-class'>
                <div class='view-select-div float-right-class select-view-dropdown-width'>
                  <label id='selectId-description' style='display: none;'>select view</label>
                  <c11n-dropdown [menuData]='dropdownMenuOptions' labelText='' (stateChange)='viewRedirect($event)'
                    placeholderText='Select view' variant='basic' class='selectViewDropdown'
                    aria-label='select view'></c11n-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='row mt-4'>
          <span class='confirmations-are-av' [innerHTML]='confirmsContent.confirmsDescription'></span>
        </div>

        <div class='row mt-3'>
          <div class='col-3 c11n-grid-story__cell d-xs-block d-md-none pt-3'>
            <p>{{ content.confirmationsContent.filterSection.filterLabel }}</p>
          </div>
          <div class='col-8 col-md-12 c11n-grid-story__cell filter-div'>
            <div class='d-inline-block me-4 col-12 col-lg-2 col-md-2 col-sm-12 c11n-grid-story__cell'>
              <c11n-select label='' hintText='' labelText='' class='hide-select-label-and-error'
                (change)="getSelectedValue($event, 'YEAR')">
                <select c11nSelectInput id='select-year-id' class='c11n-select__box__select' aria-label='select year'>
                  <option *ngFor='let y of years; let i = index' [value]='y'>{{ y }}</option>
                </select>
              </c11n-select>
            </div>

            <div class='d-inline-block me-4 col-12 col-lg-2 col-md-3 c11n-grid-story__cell'>
              <c11n-select label='' hintText='' labelText='' class='hide-select-label-and-error'
                (change)="getSelectedValue($event, 'MONTH')">
                <select c11nSelectInput id='select-month-id' class='c11n-select__box__select' aria-label='select month'
                  [(ngModel)]='month'>
                  <option *ngFor='let m of months; let i = index; trackBy: trackByFn' [value]='m'>
                    {{ m }}
                  </option>
                </select>
              </c11n-select>
            </div>
            <div class='d-inline-block me-4 col-12 col-lg-3 col-md-3 c11n-grid-story__cell'>
              <c11n-select label='' hintText='' labelText='' class='hide-select-label-and-error'
                (change)="getSelectedValue($event, 'confirmType')">
                <select c11nSelectInput id='select-confirmType-id' class='c11n-select__box__select'
                  aria-label='select confirmation type' [(ngModel)]='confirmType'>
                  <option *ngFor='let m of confirmationType; let i = index' [value]='m'>
                    {{ m }}
                  </option>
                </select>
              </c11n-select>
            </div>

            <div class='d-inline-block col-12 col-md-1 col-sm-12 c11n-grid-story__cell'>
              <c11n-button buttonType='button' size='medium' variant='primary' class='d-inline-block go-button'
                labelText='Update Table' (clickEvent)='searchConfirms()'>
              </c11n-button>
            </div>
          </div>
        </div>

        <c11n-spinner *ngIf='isLoaderIndicator' class='spinner-on-screen' size='medium' colorMode='on-light'
          spinnerStatus='Loading...'>
        </c11n-spinner>

        <div class="for-scroll" #contentElement data-id='scrollable_table' (scroll)='onScroll()'>

          <table class='c11n-table c11n-table--small c11n-table--sticky-th-horizontal c11n-table--sticky-th-vertical'
            data-cy='recent-trans-table' data-vui-sticky-elements-parent='' id='confirms-table'>
            <div id='overlay' [hidden]='hideReset'>
              <div class='d-inline-block col-12 col-md-1 col-sm-12 c11n-grid-story__cell'>
                <c11n-button buttonType='button' size='medium' variant='primary' class='reset-button'
                  [labelText]='reset' (clickEvent)='resetFilters()'>
                </c11n-button>
              </div>

            </div>
            <thead confirmsStickyHeader>
              <tr class='c11n-table__tr'>
                <th scope='col' class='c11n-table__thead-th' [attr.aria-sort]='dateSortingState'
                  [ngClass]="[dateSortingState, dateSortingState !== 'none' ? 'c11n-table__thead-th--active-sort': '']">
                  <button type='button' (click)="sortColumn('date')"
                    class='c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal' title='Sort by date'
                    [attr.aria-label]="'Sorted ' + dateSortingState" style='width: 100%;'>
                    <span class='c11n-link__content'>
                      <span id='date_column'
                        class='c11n-link__text type-header'>{{content.confirmationsContent.tableSection.dateColumnLabel}}</span>
                      <svg viewBox='0 0 8 14' focusable='false' aria-hidden='true'>
                        <use xlink:href='#c11n-table-sort-arrow'></use>
                        <use xlink:href='#c11n-table-sort-arrow'></use>
                      </svg>
                    </span>
                  </button>
                  <div data-id='scroll_left' class='scroll-left hide-arrows-mobile' hidden (click)='scrollLeft()'>
                    <c11n-icon [name]="'caret-left'" [size]="'small'"></c11n-icon>
                  </div>
                </th>

                <th scope='col' class='c11n-table__thead-th confirm-type-width'
                  [attr.aria-sort]='confirmTypeSortingState'
                  [ngClass]="[confirmTypeSortingState, confirmTypeSortingState !== 'none' ? 'c11n-table__thead-th--active-sort': '']">

                  <button type='button' (click)="sortColumn('confirmationEvent')"
                    class='c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal arrow-button'
                    title='Sort by Confirmation Type' [attr.aria-label]="'Sorted ' + confirmTypeSortingState">
                    <span class='c11n-link__content account_align'>
                      <span id='confirm_type'
                        class='c11n-link__text type-header account-header'>{{content.confirmationsContent.tableSection.confirmationTypeColumnLabel}}</span>                      
                      <svg viewBox='0 0 8 14' focusable='false' aria-hidden='true'>
                        <use xlink:href='#c11n-table-sort-arrow'></use>
                        <use xlink:href='#c11n-table-sort-arrow'></use>
                      </svg>
                    </span>
                  </button>
                </th>
                <th scope='col' class='c11n-table__thead-th account-header confirm-description-style'
                  [attr.aria-sort]='descriptionSortingState'
                  [ngClass]="[descriptionSortingState, descriptionSortingState !== 'none' ? 'c11n-table__thead-th--active-sort': '']">

                  <button type='button' (click)="sortColumn('confirmationDescription')"
                    class='c11n-link c11n-link--secondary c11n-link--icon c11n-link--normal arrow-button'
                    title='Sort by confirmation description' [attr.aria-label]="'Sorted ' + descriptionSortingState">
                    <span class='c11n-link__content'>
                      <span id='description_column'
                        class='c11n-link__text type-header'>{{content.confirmationsContent.tableSection.descriptionColumnLabel}}</span>
                      <svg viewBox='0 0 8 14' focusable='false' aria-hidden='true'>
                        <use xlink:href='#c11n-table-sort-arrow'></use>
                        <use xlink:href='#c11n-table-sort-arrow'></use>
                      </svg>
                    </span>
                  </button>
                </th>
                <!--responsivedownload class is For Mobile view-->
                <th scope='col' class='c11n-table__thead-th c11n-table__thead-th--align-left'>
                  <span id='download' class='description-header'>
                    {{ content.confirmationsContent.tableSection.viewDownloadColumnLabel }}
                  </span>
                </th>
                <th class="sticky-right gradient-right">
                  <div data-id="scroll_right" class="scroll-button scroll-right" (click)="scrollRight()">
                    <c11n-icon [name]="'caret-right'" [size]="'small'"></c11n-icon>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf='shouldShowErrorBannerForNoData'>
                <td [colSpan]='4'>
                  <div class='c11n-banner c11n-banner--small c11n-banner--error'>
                    <div class='c11n-banner__leading-icon'>
                      <svg class='c11n-error-small error-icon-style' role='img' aria-hidden='false' focusable='true'>
                        <title>error</title>
                        <use xlink:href='#error-small'></use>
                      </svg>
                    </div>

                    <div class='c11n-banner__main'>
                      <div class='c11n-banner__content'>
                        <div class='c11n-banner__body'>
                          <div class='c11n-text-md--crop'>{{ contentBody }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf='shouldShowErrorBannerForApiFailure'>
                <td [colSpan]='4'>
                  <div class='c11n-banner c11n-banner--small c11n-banner--error'>
                    <div class='c11n-banner__leading-icon'>
                      <svg class='c11n-error-small error-icon-style' role='img' aria-hidden='false' focusable='true'>
                        <title>error</title>
                        <use xlink:href='#error-small'></use>
                      </svg>
                    </div>

                    <div class='c11n-banner__main'>
                      <div class='c11n-banner__content'>
                        <div class='c11n-banner__body'>
                          <div class='c11n-text-md--crop'>{{ apiFailureErrorBody }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class='c11n-table__tr' *ngFor='let statement of standardConfirmList; let i = index'>
                <td
                  class='recent-transaction-type c11n-table__td c11n-table__td--align-left c11n-table__td c11n-table__td--align-left sticky-left'>
                  {{ statement.endDate }}
                </td>

                <td class='recent-transaction-type c11n-table__td c11n-table__td--align-left '>
                  {{ statement.confirmationEvent }}
                </td>
                <td class='recent-transaction-type c11n-table__td c11n-table__td--align-left'
                  [innerHTML]='statement.confirmationDescription'></td>
                <td class='ps-5 recent-transaction-type c11n-table__td c11n-table__td--align-left'>
                  <c11n-icon _ngcontent-bii-c24 name='download' class='responsive_pdf_icon' tabIndex='0'
                    title='Pdf download icon' id='download-icon-{{i}}'
                    (click)='downloadpdf(statement.confirmationId, $event)'
                    (keydown.space)='keyHandler($event, statement.confirmationId);'
                    (keydown.enter)='keyHandler($event, statement.confirmationId);'
                    attr.aria-label="Download a pdf {{statement.confirmationEvent}} generated on {{statement.endDate | date: 'longDate'}} with description {{statement.confirmationDescription}}"
                    role='button'>
                    <svg aria-hidden='true' focusable='false' class='c11n-download-small'>
                      <use xlink:href='#download-small'></use>
                    </svg>
                  </c11n-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <a class='c11n-link c11n-link--secondary show-more-link c11n-text-sm c11n-link--independent c11n-link--normal'
          id='show-more' tabIndex='0' (keydown.space)='keyHandlerShowMore($event);'
          (keydown.enter)='keyHandlerShowMore($event);' (click)='showMoreConfirms($event)' [hidden]='hideShowMore'
          [ngClass]="hideReset ? '' : 'disabled-link' ">
          <span class='c11n-link__content'>Show more</span>
        </a>
        <!-- </div> -->

        <div class='bottom-paragraph-section mt-3' [innerHTML]='confirmsContent.notesAndDisclosures'></div>

        <div class='disclaimer mb-4' [innerHTML]='confirmsContent.disclaimer'></div>
      </div>
    </div>
  </div>
</ss-lib-page-layout>

<c11n-modal-dialog #chooseColumnModal [headingText]='confirmsContent.heading'>
  <span c11n-modal-dialog-body>
    <ng-container>
      <div class='row'>
        <div class='col-sm-10'>
          <c11n-button buttonType='button' size='small' [variant]="showConfirmType ? 'primary' : 'secondary'"
            class='d-inline-block update-button modal-button'
            [labelText]='content.confirmationsContent.tableSection.confirmationTypeColumnLabel' (clickEvent)='
              toggleAccountdownload(
                content.confirmationsContent.tableSection.confirmationTypeColumnLabel
              )
            ' c11nModalButtonPrimary></c11n-button>
        </div>
        <div class='col-sm-10 my-1'>
          <c11n-button buttonType='button' size='small' [variant]="showdescription ? 'primary' : 'secondary'"
            class='d-inline-block update-button modal-button'
            [labelText]='content.confirmationsContent.tableSection.descriptionColumnLabel' (clickEvent)='
              toggleAccountdownload(
                content.confirmationsContent.tableSection.descriptionColumnLabel
              )
            ' c11nModalButtonPrimary></c11n-button>
        </div>
        <div class='col-sm-10'>
          <c11n-button buttonType='button' size='small' [variant]="showpdf ? 'primary' : 'secondary'"
            class='d-inline-block update-button modal-button'
            [labelText]='content.confirmationsContent.tableSection.viewDownloadColumnLabel' (clickEvent)='
              toggleAccountdownload(
                content.confirmationsContent.tableSection.viewDownloadColumnLabel
              )
            ' c11nModalButtonPrimary>
          </c11n-button>
        </div>
      </div>
    </ng-container>
  </span>
</c11n-modal-dialog>


<c11n-modal-dialog #errorModal [headingText]='errorPdfDownload' class='error-modal'>
  <span c11n-modal-dialog-body>
    <ng-container>
      <div class='row'>
        <span>We apologize for any inconvenience. You'll need to try again later.</span>
        <c11n-button style='display: none;' c11nModalButtonPrimary></c11n-button>
      </div>
    </ng-container>
  </span>
</c11n-modal-dialog>