import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from './auth.interceptor';

import {
  WindowRef,
  ENVIRONMENT_CONFIG as COMPONENT_LIB_ENVIRONMENT_CONFIG,
  Environment as ComponentLibEnvironment,
  ExternalLinkModule,
  PageLayoutModule,
  SecondaryNavigationModule,
  WindowRefModule
} from '@vanguard/secure-site-components-lib';


import { AppRoutingModule } from './app-routing.module';
import { ButtonModule } from '@vg-constellation/angular-13/button';
import { SelectModule } from '@vg-constellation/angular-13/select';
import { IconModule } from '@vg-constellation/angular-13/icon';
import { LinkModule } from '@vg-constellation/angular-13/link';
import { ModalDialogComponent, ModalDialogModule } from '@vg-constellation/angular-13/modal-dialog';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmService } from '../app/services/confirm/confirm.service';
import { CaasService } from './services/caas/caas.service';
import { AppComponent } from './app.component';
import { ConfirmComponent } from '../app/components/confirm/confirm.component';
import { TetriaryNavigationComponent } from '../app/components/tetriary-navigation/tetriary-navigation.component';
import { SpinnerModule } from '@vg-constellation/angular-13/spinner';
import { GatekeeperService } from './services/gatekeeper/gatekeeper.service';
import { FormsModule } from '@angular/forms';
import otlAngularConfig from '@vanguard/otl-angular-wrapper'
import {
  OpenTelemetryInterceptorModule,
  OtelColExporterModule,
  W3CTraceContextPropagatorModule
} from '@jufab/opentelemetry-angular-interceptor'
import { DropdownModule } from '@vg-constellation/angular-13/dropdown';
import { StickyHeaderDirective } from './directives/sticky-header.directive';

@NgModule({
  declarations: [AppComponent, ConfirmComponent, TetriaryNavigationComponent, StickyHeaderDirective],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, ButtonModule, SelectModule, SpinnerModule, IconModule, ModalDialogModule.forRoot(),
    LinkModule,
    PageLayoutModule,
    SecondaryNavigationModule,
    WindowRefModule,
    ExternalLinkModule,
    FormsModule,
    OpenTelemetryInterceptorModule.forRoot(
      otlAngularConfig({
        // substitute the following placeholders with your own application specific values
        serviceName: 'RQF',
        appPrefix: 'RQF', // you may also want to get this from the environment object, if present
        sysLevel: environment.deployment // or whatever key this value is stored under
      })
    ),
    OtelColExporterModule,
    W3CTraceContextPropagatorModule,
    DropdownModule,
  ],
  providers: [ConfirmService, ModalDialogComponent,
    CookieService,
    CaasService,
    GatekeeperService,
    {
      provide: COMPONENT_LIB_ENVIRONMENT_CONFIG,
      useValue: {
        isProd: environment.production, // a boolean indicating whether library is being used in a production environment
        appName: 'confirmations.frontend', // a string identifying the app hosting the component library.
        isPerf: environment.performance,
        isInternal: environment.internal
      } as ComponentLibEnvironment,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: function (http: HttpClient, meta: Meta, windowRef: WindowRef,
                            cookieService: CookieService, caasService: CaasService, gatekeeperservice: GatekeeperService) {
        const $window: Window = windowRef.nativeWindow;
        return async () => {
          await http
            .get(`${environment.CONFIRMATIONS.BASE_URL}/adobe`, { withCredentials: true })
            .toPromise()
            .then(async (data: any) => {
              /* Calling CAAS service */
              await caasService.getContent();

              const spoid = data.sPOID;
              meta.updateTag({ name: 'DCSext.poid', content: data.poid });
              meta.updateTag({ name: 'DCSext.sPoid', content: spoid }); // Used for Floodlight
              meta.updateTag({ name: 'WT.seg_6', content: data.crewFlag });
              $window.spoid = spoid; // Used for Medallia
              $window.document.body.hidden = false;
              gatekeeperservice.getGatekeeperFLag();
            })
            .catch(() => {
              /*
               * If Adobe call fails & POID is present in browser cookies (POID will not be present for external users) &
               * 'secureMessage' flag is present in the url that means this is an internal user.
               * Redirect to statements-i.webt.vanguard.com
              */
              const currentUrl = new URL(window.location.href);
              const goToInternal = currentUrl.searchParams.get('secureMessage');
              if ( cookieService.get('POID') && goToInternal ) {
                document.location.href = environment.CONFIRMATIONS.INTERNAL_URL;
              }
              else {
                $window.document.location.href = environment.LOGON_URL;
                // Ensure that app initialization is aborted since we're going to the (external) logon page.
                // return Promise.reject();
              }
            });
        };
      },
      deps: [HttpClient, Meta, WindowRef, CookieService, CaasService, GatekeeperService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    Title
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
