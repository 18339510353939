import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GatekeeperService {
  constructor(private readonly http: HttpClient, private readonly router: Router) { }

  public isFeatureSwitchedOn(featureId: string): Observable<boolean> {
    const url = `${environment.CONFIRMATIONS.BASE_URL}${environment.CONFIRMATIONS.GATEKEEPER_URL}/${featureId}`;
    return this.http
      .get<any>(url)
      .pipe(
        map((isToggle) => {
          return isToggle;
        }),
        catchError(() => {
          return of(false);
        }),
      );
  }

  public redirectToModernizedApp(): void {
    this.router.navigate(['']);
  }

  getGatekeeperFLag() {
    this.isFeatureSwitchedOn(environment.GATEKEEPER_FEATURE_ID).toPromise()
      .then((response: boolean) => {
        if (response) {
          // redirect to legacy if gatekeeper is set to true
          document.location.href = environment.LEGACY_URL + '?redirect=true';
        } else {
          this.redirectToModernizedApp();
        }
      }).catch((err) => {
        console.log('Gatekeeper catch block', err);
      })
  }
  /**
   * Future scope for gatekeeper
   */
  // public isFeaturesMultipleSwitchedOn(
  //   featureIds: string[],
  // ): Observable<{ [key: string]: boolean }> {
  //   const url = environment['gatekeeper'];
  //   return this.http
  //     .get<any>(`${url}/multi`, {
  //       params: { featureId: featureIds.join(',') },
  //       withCredentials: true,
  //     })
  //     .pipe(
  //       map((featureList) => {
  //         const featureMap = {};
  //         for (let i = 0; i < featureList.length; i++) {
  //           featureMap[featureList[i].featureId] = featureList[i].enabled;
  //         }
  //         return featureMap;
  //       }),
  //       catchError(() => {
  //         const data = {};
  //         for (let i = 0; i < featureIds.length; i++) {
  //           data[featureIds[i]] = false;
  //         }
  //         return of(data);
  //       }),
  //     );
  // }
}
