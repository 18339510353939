import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LoggerLevel, LogMessage, Log } from './logger.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  loggerEndpoint: string;

  constructor(private readonly http: HttpClient, private readonly cookie: CookieService) {
    this.loggerEndpoint = `${environment.CONFIRMATIONS.BASE_URL}${environment.CONFIRMATIONS.CLIENT_LOG}`;
  }

  public info(message: LogMessage): void {
    this.sendLog(LoggerLevel.INFO, message);
  }

  public warn(message: LogMessage): void {
    this.sendLog(LoggerLevel.WARN, message);
  }

  public error(message: LogMessage): void {
    this.sendLog(LoggerLevel.ERROR, message);
  }

  private sendLog(level: LoggerLevel, message: LogMessage): void {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-XSRF-TOKEN': this.cookie.get('XSRF-TOKEN'),
      }),
      withCredentials: true,
    };

    this.http.post(this.loggerEndpoint, new Log(level, message), httpOptions).toPromise();
  }
}
