import { Injectable } from '@angular/core';
import {
  ApolloLink,
  ApolloQueryResult,
  from,
  InMemoryCache,
  QueryOptions,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../environments/environment';
import { DocumentNode } from 'graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, take, timeout } from 'rxjs/operators';
import { LoggerService } from '../logger/logger.service';

interface GraphQLServiceError {
  feature: string;
  uri?: string;
  message: string;
  error: Maybe<Error> | string;
}

@Injectable({
  providedIn: 'root',
})
export class GraphQLService {
  constructor(
    private readonly apollo: Apollo,
    private readonly httpLink: HttpLink,
    private readonly cookie: CookieService,
    private readonly loggerService: LoggerService
  ) {
    const basic = setContext(this.getAcceptHeader);

    const auth = setContext(this.getAuthHeader);

    const errorLink = onError(this.errorHandler);

    const apolloLink = ApolloLink.from([
      basic,
      auth,
      this.httpLink.create({
        uri: `${environment.GRAPHQL_URI}`,
        withCredentials: true,
      }),
    ]);

    this.apollo.create({
      ...APOLLO_OPTIONS,
      name: 'confirmations.frontend',
      cache: new InMemoryCache(),
      link: from([errorLink, apolloLink]),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAcceptHeader(operation, context) {
    return {
      headers: {
        Accept: 'application/json',
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAuthHeader = (operation, context) => {
    return {
      headers: {
        'X-XSRF-TOKEN': this.cookie.get('XSRF-TOKEN'),
      },
    };
  };

  mapGqlErrors(graphQLErrors, networkError): GraphQLServiceError[] {
    const errors: GraphQLServiceError[] = [];

    if (graphQLErrors) {
      graphQLErrors.forEach(({ originalError, message }) =>
        errors.push({
          feature: 'ApolloService',
          uri: `${environment.GRAPHQL_URI}`,
          message,
          error: originalError || message,
        }),
      );
    }

    if (networkError) {
      errors.push({
        feature: 'ApolloService',
        message: networkError.message,
        error: networkError,
      });
    }

    return errors;
  }

  errorHandler = (error) => {
    this.mapGqlErrors(error.graphQLErrors, error.networkError).forEach((error) => {
      console.error(error);
      this.loggerService.error({
        message: error.message,
        app: 'RQF'
      })
    });
  };

  errorLogger = (error) => {
    console.error({
      feature: 'GraphQLService',
      message: error.message,
      error,
    });

    return throwError(error.message);
  };

  /**
   * Makes a GraphQL query using Apollo Client's query function
   * If there are errors (it may still return a 200 OK), log it and reject the Promise
   * If no errors, extract actual data under the "data" object and resolves
   * @param query Stringified GraphQL Query
   */
  query(query: DocumentNode, noCache = false): Observable<ApolloQueryResult<any>> {
    const fetchPolicyString = noCache ? 'no-cache' : 'cache-first';

    const queryObject: QueryOptions = {
      query,
      errorPolicy: 'all',
      fetchPolicy: fetchPolicyString,
    };

    return this.apollo
      .query(queryObject)
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      .pipe(timeout(100000), catchError(this.errorLogger), take(1));
  }
}
