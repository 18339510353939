const CONFIRMATIONTYPE = 0
const NOTIFICATION = 1
const MONETORY = 2
const ACCOUNTMAINTAINANCE = 3
export const content = {
  pageTitle: 'Confirmations | Vanguard',
  contentData: {
    transactionHistory: "Transaction history",
    orderStatus: "Order status",
    statements: "Statements",
    confirmations: "Confirmations",
    api_error_heading: "Data unavailable",
    api_error_body: "Our system is temporarily unable to display some of your account information. We apologize for the inconvenience and appreciate your patience while we fix these issues. " +
                    "Please refresh in a few minutes or choose another page."
  },
  linksContent: {
    secureMessage: {
      url: 'https://personal.vanguard.com/us/SMCInformationCenter',
    },
    transactions: {
      url: 'http://oedesktop.vanguard.com/us/Transactions',
    },
    orderStatus: {
      url: 'http://oedesktop.vanguard.com/web/cfv/order-status/',
    },
    confirmations: {
      url: 'http://oedesktop.vanguard.com/us/Confirms',
    },
    statements: {
      url: 'http://oedesktop.vanguard.com/us/Statements',
    }
  },
  confirmationsContent: {
    confirmationsHeading: 'All accounts',
    confirmationsHeadingLinkText: 'Duplicate statements and transaction confirmations',
    confirmationsHeadingLink: '',
    pageDescription: {
      paragraph1: {
        text: "Confirmations are available for the current year and the previous 5 years."
      }
    },
    filterSection: {
      confirmationTypeDropdownContent: [
        {value:CONFIRMATIONTYPE,type:'All confirmation type'},
        {value:NOTIFICATION,type:'Notification'},
        {value:MONETORY,type:'Monetory'},
        {value:ACCOUNTMAINTAINANCE,type:'Account Maintainence'}
      ],
      filterLabel: "Sort:",
      filterButtonLabel: "Go"
    },
    tableSection: {
      dateColumnLabel: "Date",
      confirmationTypeColumnLabel: "Confirmation Type",
      descriptionColumnLabel: 'Description',
      viewDownloadColumnLabel: "View/download"
    },
    brokerageConfirmationSectioin: {
      paragraph1: {
        linkText: "View",
        link: "https://oedesktop.vanguard.com:54443/pdf/CFconfirmbacker.pdf",
        text: "brokerage confirmation disclosures for trades on the Vanguard Brokerage platform."
      }
    },
    declaration: {
      text1: "*Brokerage assets are held by Vanguard Brokerage Services, a division of Vanguard Marketing Corporation, member FINRA [",
      finraText: "https://www.finra.org/investors#/",
      finraLink: "https://www.finra.org/investors#/",
      sipcText: "https://www.sipc.org/",
      sipcLink: "https://www.sipc.org/",
      text2: "] and SIPC[",
      text3: "]"
    }
  },
  caasContent: {
    "LatestModified": 1649175820392,
    "Results": 1,
    "Error": "",
    "Data": {
      "confirms": {
        "metadata": {
          "path": "/content-fragments/caas/statements_confirms/confirms",
          "variation": "master"
        },
        "content": {
          "confirmsDescription": "<p>Confirmations are available for the current year and the previous 5 years.</p>\n",
          "dropdownOptions": {
            "option_01": {
              "optionLabel": "Duplicate statements and transaction confirmations",
              "optionUrl": "https://personal.vanguard.com/us/AddrViewController"
            }
          },
          "duplicatedStatementLabel": "Duplicate statements and transaction confirmations",
          "heading": "All accounts",
          "notesAndDisclosures": "<p><a href=\"https://personal.vanguard.com/pdf/CFconfirmbacker.pdf\" aria-label=\"View brokerage confirmation disclosures for trades on the Vanguard Brokerage platform\" target=\"_blank\">View</a>&nbsp;brokerage confirmation disclosures for trades on the Vanguard Brokerage platform.</p>\n",
          "browserTitle": "Confirmations | Vanguard",
          "apiErrorBody": "There are no statements available for this time period. Please use the dropdown to make another selection.",
          "title": "Confirmations",
          "apiErrorHeading": "Data unavailable",
          "disclaimer": "<p>*Brokerage assets are held by Vanguard Brokerage Services, a division of Vanguard Marketing Corporation, member FINRA [<a href=\"https://www.finra.org/investors#/\">https://www.finra.org/investors#/</a>&nbsp;] and SIPC[<a href=\"https://www.sipc.org/\">https://www.sipc.org/</a>&nbsp;]</p>\n",
          "tabOptions": {
            "tab-option-02": {
              "optionLabel": "Order status",
              "optionUrl": "https://personal.vanguard.com/web/cfv/order-status/"
            },
            "tab-option-03": {
              "optionLabel": "Confirmations"
            },
            "tab-option-01": {
              "optionLabel": "Transaction history",
              "optionUrl": "https://transactions.web.vanguard.com/"
            },
            "tab-option-04": {
              "optionLabel": "Statements",
              "optionUrl": "https://statements.web.vanguard.com"
            }
          }
        }
      }
    }
  }
};
